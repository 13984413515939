var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap vgm_pop vgm_area",
      staticStyle: { width: "950px", "max-height": "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONEX050G020.001"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content_box" }, [
          _c("div", { staticClass: "bkbl_no text_right" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX050G020.002"))),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "txt_bar2" }),
            _c("span", [_vm._v(_vm._s(_vm.formData.blNo))]),
          ]),
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.003")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.004")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.005")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.006")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.007")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.010")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.011")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.012")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.013")))]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.formData.vgmList, function (vgmVo, index) {
                return _c("tr", { key: index }, [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: vgmVo.cntrNo,
                          expression: "vgmVo.cntrNo",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "cntr_" + index,
                        readonly: _vm.formData.viewType != "SHAVGM",
                      },
                      domProps: { value: vgmVo.cntrNo },
                      on: {
                        blur: function ($event) {
                          return _vm.searchCntrNoChk(vgmVo.cntrNo, index)
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(vgmVo, "cntrNo", $event.target.value)
                          },
                          function ($event) {
                            vgmVo.cntrNo = vgmVo.cntrNo.toUpperCase()
                          },
                        ],
                      },
                    }),
                  ]),
                  _c("td", { staticClass: "full" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: vgmVo.msrMethCd,
                            expression: "vgmVo.msrMethCd",
                          },
                        ],
                        attrs: { id: "method_" + index },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                vgmVo,
                                "msrMethCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              $event.preventDefault()
                              return _vm.autoCalculate(index)
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.selectOptions, function (option) {
                        return _c(
                          "option",
                          {
                            key: option.value,
                            domProps: { value: option.value },
                          },
                          [_vm._v(" " + _vm._s(option.text) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "td",
                    [
                      (vgmVo.msrMethCd === "SM2" ||
                        _vm.parentInfo.tareModifyYn === "Y") &&
                      _vm.formData.tkCntrExistsYn === "N"
                        ? _c("e-input-number", {
                            attrs: {
                              "is-comma": true,
                              point: 3,
                              id: "tareWt_" + index,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.autoCalculate(index)
                              },
                            },
                            model: {
                              value: vgmVo.actTareWt,
                              callback: function ($$v) {
                                _vm.$set(vgmVo, "actTareWt", $$v)
                              },
                              expression: "vgmVo.actTareWt",
                            },
                          })
                        : [_vm._v(_vm._s(vgmVo.actTareWt))],
                    ],
                    2
                  ),
                  _c(
                    "td",
                    [
                      _c("e-input-number", {
                        attrs: {
                          "is-comma": true,
                          point: 3,
                          id: "wt_" + index,
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.searchCntrNoChk(vgmVo.cntrNo, index)
                          },
                        },
                        model: {
                          value: vgmVo.wt,
                          callback: function ($$v) {
                            _vm.$set(vgmVo, "wt", $$v)
                          },
                          expression: "vgmVo.wt",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: vgmVo.msrAdmrSgn,
                          expression: "vgmVo.msrAdmrSgn",
                        },
                      ],
                      attrs: { type: "text", id: "sign_" + index },
                      domProps: { value: vgmVo.msrAdmrSgn },
                      on: {
                        keyup: function ($event) {
                          return _vm.valEngCheck($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(vgmVo, "msrAdmrSgn", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c(
                    "td",
                    [
                      !vgmVo.bkgNo && _vm.formData.bkgList.length == 1
                        ? [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vgmVo.bkgNo,
                                  expression: "vgmVo.bkgNo",
                                },
                              ],
                              attrs: { type: "text", id: "bkgNo_" + index },
                              domProps: { value: vgmVo.bkgNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vgmVo, "bkgNo", $event.target.value)
                                },
                              },
                            }),
                          ]
                        : [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vgmVo.bkgNo,
                                  expression: "vgmVo.bkgNo",
                                },
                              ],
                              attrs: { type: "text", id: "bkgNo_" + index },
                              domProps: { value: vgmVo.bkgNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vgmVo, "bkgNo", $event.target.value)
                                },
                              },
                            }),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "td",
                    [
                      _c("e-date-picker", {
                        attrs: { id: "dtm_" + index },
                        model: {
                          value: vgmVo.msrDtm,
                          callback: function ($$v) {
                            _vm.$set(vgmVo, "msrDtm", $$v)
                          },
                          expression: "vgmVo.msrDtm",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: vgmVo.msrCrtfNo,
                          expression: "vgmVo.msrCrtfNo",
                        },
                      ],
                      attrs: { type: "text", maxlength: "70" },
                      domProps: { value: vgmVo.msrCrtfNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(vgmVo, "msrCrtfNo", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("td", [
                    vgmVo.vgmSeq && _vm.vgmDelYn === "N"
                      ? _c(
                          "button",
                          {
                            staticClass: "tbl_icon filedel",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.vgmDelete(vgmVo.vgmSeq, vgmVo.cntrNo)
                              },
                            },
                          },
                          [_vm._v("close")]
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _vm.lang == "KOR"
            ? _c("p", { staticClass: "txt_desc asterisk" }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX050G020.014"))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("table", { staticClass: "tbl_col mt20" }, [
            _vm._m(1),
            _c("tbody", [
              _c("tr", [
                _c("th", { attrs: { rowspan: "2" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX050G020.015"))),
                ]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.016")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.017")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.018")))]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "border_left" }, [
                  _vm._v(_vm._s(_vm.formData.vgmList.totalQty)),
                ]),
                _c("td", [_vm._v(_vm._s(_vm.formData.vgmList.totalFini))]),
                _c("td", [_vm._v(_vm._s(_vm.formData.vgmList.totalWt))]),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "tbl_row mt10" }, [
            _vm._m(2),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.019")))]),
                _vm._v(" "),
                _c("td", { staticClass: "text_left" }, [
                  _c("span", [
                    _c("div", { staticClass: "div-file" }, [
                      _c("button", { on: { click: _vm.handleInputDivClick } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX050G020.023"))),
                      ]),
                      _c("span", { staticStyle: { "padding-right": "10px" } }, [
                        _vm._v(_vm._s(_vm.filename)),
                      ]),
                    ]),
                    _c("input", {
                      ref: "uploadFile",
                      staticStyle: { width: "0", height: "0" },
                      attrs: { type: "file", id: "inpFile" },
                      on: {
                        change: function ($event) {
                          return _vm.fnFileUpload()
                        },
                      },
                    }),
                  ]),
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "button sm",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.excelUpload.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX050G020.020")) + " "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "button sm",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.excelDownload("Vgm_Template.xls")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.021")))]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("p", { staticClass: "txt_desc" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX050G020.025"))),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "txt_desc" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX050G020.026"))),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.vgmUpdate()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.027")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.028")))]
          ),
        ]),
      ]),
      _c(
        "win-layer-pop",
        { staticClass: "winLayerPop" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popupParams },
                on: {
                  close: _vm.layerClose,
                  "set-dext-file-info": _vm.arrangeUploadParam,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "19%" } }),
      _c("col", { attrs: { width: "13%" } }),
      _c("col", { attrs: { width: "11%" } }),
      _c("col", { attrs: { width: "12%" } }),
      _c("col", { attrs: { width: "6%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "30%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "82px" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }