<template>
  <div class="popup_wrap vgm_pop vgm_area" style="width:950px; max-height:600px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.ONEX050G020.001') }}</h1> <!-- VGM 정보 등록 -->
      <div class="content_box">
        <!-- content_box -->
        <!-- <h2 class="content_title">B/L No. {{params.blNo}}</h2> -->
        <div class="bkbl_no text_right">
          <span class="title">{{ $t('msg.ONEX050G020.002') }}</span> <!-- B/L No. -->
          <span class="txt_bar2"></span>
          <span>{{ formData.blNo }}</span>
        </div>
        <table class="tbl_col">
          <colgroup>
            <col width="14%">
            <col width="9%">
            <col width="8%">
            <col width="8%">
            <col width="19%">
            <col width="13%">
            <col width="11%">
            <col width="12%">
            <col width="6%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONEX050G020.003') }}</th> <!-- Container No. -->
              <th>{{ $t('msg.ONEX050G020.004') }}</th> <!-- Method -->
              <th>{{ $t('msg.ONEX050G020.005') }}</th> <!-- Tare Weight(kg) -->
              <th>{{ $t('msg.ONEX050G020.006') }}</th> <!-- VGM Weight(kg) -->
              <th>{{ $t('msg.ONEX050G020.007') }}</th> <!-- Signature -->
              <!-- <th v-if="formData.viewType == 'XGGVGM'">{{ $t('msg.ONEX050G020.008') }}</th> --> <!-- Company -->
              <!-- <th v-if="formData.viewType == 'XGGVGM'">{{ $t('msg.ONEX050G020.009') }}</th> --> <!-- Contact Point (Tel) -->
              <th>{{ $t('msg.ONEX050G020.010') }}</th> <!-- BKG No. -->
              <th>{{ $t('msg.ONEX050G020.011') }}</th> <!-- Verified Date -->
              <th>{{ $t('msg.ONEX050G020.012') }}</th> <!-- Verified Ref. No. -->
              <th>{{ $t('msg.ONEX050G020.013') }}</th> <!-- Del -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vgmVo, index) in formData.vgmList" :key="index">
              <td>
                <input type="text" :id="'cntr_' + index" v-model="vgmVo.cntrNo" :readonly="formData.viewType != 'SHAVGM'" @blur="searchCntrNoChk(vgmVo.cntrNo, index)" @input="vgmVo.cntrNo = vgmVo.cntrNo.toUpperCase()">
              </td>
              <td class="full">
                <select :id="'method_' + index" v-model="vgmVo.msrMethCd" @change.prevent="autoCalculate(index)">
                  <option v-for="option in selectOptions" :value="option.value" :key="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </td>
              <td>
                <!-- Tare Weight(kg) / 화주소유컨테이너, SOC인 경우 Tare 입력 가능하도록 수정 -->
                <e-input-number
                  v-if="(vgmVo.msrMethCd === 'SM2' || parentInfo.tareModifyYn === 'Y') && formData.tkCntrExistsYn === 'N'"
                  v-model="vgmVo.actTareWt"
                  :is-comma="true"
                  :point="3"
                  :id="'tareWt_' + index"
                  @input="autoCalculate(index)"
                >
                </e-input-number>
                <template v-else>{{ vgmVo.actTareWt }}</template>
              </td>
              <td>
                <e-input-number
                  v-model="vgmVo.wt"
                  :is-comma="true"
                  :point="3"
                  :id="'wt_' + index"
                  @blur="searchCntrNoChk(vgmVo.cntrNo, index)"
                >
                </e-input-number>
              </td>
              <td>
                <input type="text" :id="'sign_' + index" v-model="vgmVo.msrAdmrSgn" @keyup="valEngCheck($event)">
              </td>
              <td>
                <template v-if="!vgmVo.bkgNo && formData.bkgList.length == 1">
                  <input type="text" :id="'bkgNo_' + index" v-model="vgmVo.bkgNo">
                </template>
                <template v-else>
                  <input type="text" :id="'bkgNo_' + index" v-model="vgmVo.bkgNo">
                </template>
              </td>
              <!-- <td v-if="vgmVo.viewType == 'XGGVGM'">
                <input type="text" v-model="vgmVo.msrEntrNm">
              </td>
              <td v-if="vgmVo.viewType == 'XGGVGM'">
                <input type="text" v-model="vgmVo.msrPicTelNo">
              </td> -->
              <td>
                <!-- <input type="text" v-model="vgmVo.msrDtm" maxlength="12"> -->
                <!-- <date-range-picker> -->
                <e-date-picker :id="'dtm_' + index" v-model="vgmVo.msrDtm" />
              </td>
              <td>
                <input type="text" v-model="vgmVo.msrCrtfNo" maxlength="70">
              </td>
              <td>
                <button v-if="vgmVo.vgmSeq && vgmDelYn === 'N'" class="tbl_icon filedel" @click.prevent="vgmDelete(vgmVo.vgmSeq, vgmVo.cntrNo)">close</button>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="lang == 'KOR'" class="txt_desc asterisk">{{ $t('msg.ONEX050G020.014') }}</p> <!-- VGM WEIGHT는 내폼 무게 + 고박장치 + 컨테이너 TARE WEIGHT를 총합한 정확한 무게를 기입하여 주시기 바랍니다.<br/>보여지는 TARE WEIGHT는 참고용이며, 자동합산되지 않습니다. -->

        <table class="tbl_col mt20">
          <colgroup>
            <col width="100px">
            <col width="30%">
            <col width="30%">
            <col width="30%">
          </colgroup>
          <tbody>
            <tr>
              <th rowspan="2">{{ $t('msg.ONEX050G020.015') }}</th> <!-- Total -->
              <th>{{ $t('msg.ONEX050G020.016') }}</th> <!-- Cntr Qty -->
              <th>{{ $t('msg.ONEX050G020.017') }}</th> <!-- Vgm Finished -->
              <th>{{ $t('msg.ONEX050G020.018') }}</th> <!-- Weight -->
            </tr>
            <tr>
              <td class="border_left">{{ formData.vgmList.totalQty }}</td>
              <td>{{ formData.vgmList.totalFini }}</td>
              <td>{{ formData.vgmList.totalWt }}</td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_row mt10">
          <colgroup>
            <col width="82px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONEX050G020.019') }}</th> <!-- 파일 -->
              <td class="text_left">
                <!--  탐색기 나오는 파일 첨부일 경우 사용
                  <span class="filebox">
                  <input class="upload-name" value="">
                  <label for="file">파일첨부</label>
                  <input type="file" id="file">
                </span> -->

                <span>
                  <!-- <input type="text" class="uploadFile" id="uploadFile" name="uploadFile" :value="popupParams && popupParams.length > 0 ? popupParams[0].originalName : ''" readonly> -->
                  <!-- <a class="button sm gray file" href="#" @click.prevent="this.document.getElementById('inpFile').click();">파일첨부</a> -->
                  <div class="div-file">
                    <button @click="handleInputDivClick">{{ $t('msg.ONEX050G020.023') }}</button>
                    <span style="padding-right: 10px;">{{ filename }}</span>
                  </div>
                  <input ref="uploadFile" @change="fnFileUpload()" type="file" id="inpFile" style="width: 0; height: 0;" />
                </span>

                <span>
                  <a class="button sm" href="#" @click.prevent="excelUpload">
                    {{ $t('msg.ONEX050G020.020') }}
                  </a> <!-- Excel Upload -->
                  <a class="button sm" href="#" @click.prevent="excelDownload('Vgm_Template.xls')">{{ $t('msg.ONEX050G020.021') }}</a> <!-- Excel Download -->
                </span>
              </td>
            </tr>
            <!-- <tr v-if="formData.viewType == 'XGGVGM' || formData.viewType == 'SHAVGM'">
              <th>{{ $t('msg.ONEX050G020.022') }}</th>
              <td class="text_left">
                <span>
                  <input type="text" class="certiFile" id="certiFile" name="certiFile" :value="popupParams && popupParams.length > 0 ? popupParams[0].originalName : ''" readonly>
                  <a :class="popupParams.length !=0 ? 'button sm blue' : 'button sm gray file'" href="#" @click="uploadPopup('vgm-upload-pop')">{{ $t('msg.ONEX050G020.023') }}</a>
                  <a class="button sm" href="#" @click="certiUpload">{{ $t('msg.ONEX050G020.024') }}</a>
                </span>
              </td>
            </tr> -->
          </tbody>
        </table>

        <p class="txt_desc">{{ $t('msg.ONEX050G020.025') }}</p> <!-- 국내의 경우 계측일(Verified Date), 계측인증번호(Verified Ref, No.)는 선택 입력 사항입니다. -->
        <p class="txt_desc">{{ $t('msg.ONEX050G020.026') }}</p> <!-- 다수의 부킹번호에 대해 B/L 1건으로 진행시, S/R를 먼저 제출하여 부킹과 B/L번호가 매칭되도록 승인 된 이후 VGM 입력 바랍니다. -->
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="vgmUpdate()">{{ $t('msg.ONEX050G020.027') }}</a> <!-- 저장 -->
        <a class="button gray lg" href="#" @click.prevent="$emit('close')">{{ $t('msg.ONEX050G020.028') }}</a> <!-- 취소 -->
      </div>
    </div><!-- popup_cont -->
    <win-layer-pop class="winLayerPop">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="layerClose"
        :parent-info="popupParams"
        @set-dext-file-info="arrangeUploadParam"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import bookings from '@/api/rest/trans/bookings'
import commons from '@/api/services/commons'
import EDatePicker from '@/components/common/EDatePicker'
import EInputNumber from '@/components/common/EInputNumber'
import trans from '@/api/rest/trans/trans'

// TODO
//input 데이터 지워도 되는것, 안되는것 구분 종류 3개 - 운영팀 문의사항 33번 SHA, SZP, XGG
//bkgno, Method 전부 인풋에 들어오도록 다시해야함

export default ({
  name: 'VgmPop',
  components: {
    EDatePicker,
    EInputNumber,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'vgm-upload-pop': () => import('@/pages/trans/popup/VgmUploadPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          methCd: '',
          type: '',
          uiFlag: '',
          tareModifyYn: 'N',
          tkCntrExistsYn: 'N', // Tank Container 존재 여부
          srRno: ''
        }
      }
    }
  },
  data () {
    return {
      firstUploadFlag: false,
      lang: '',
      userCtrCd: '',
      selectOptions: [
        { text: '---------', value: '' },
        { text: 'SM1', value: 'SM1' },
        { text: 'SM2', value: 'SM2' }
      ],
      formData: {
        blNo: '',
        methCd: '',
        uiFlag: '',
        type: '',
        vgmList: [],
        viewType: '',
        bkgList: [],
        cntrList: [],
        //파일 정보
        uploadFileInfos: [],
        rawCntrWtList: []
      },
      customComponent: null,
      popupParams: [],
      uploadParams: [],
      tempFile: {
        id: '',
        msg: ''
      },
      filename: '',
      now: '',
      vgmDelYn: 'Y'
    }
  },
  computed: {
    ...rootComputed
  },
  async created () {
    //세션값 셋팅
    if (this.auth !== undefined) {
      this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
    }
    if (this.memberDetail !== undefined) {
      this.userCtrCd = this.memberDetail.userCtrCd
    }

    //VGM 컨테이너 불러오기
    await this.getVgm()

    // 서류마감 날짜 확인해서 VGM 삭제가능 여부 확인
    await this.checkVgmDelYn()
  },
  methods: {
    layerClose (flag) {
      if (flag === 'pop2') {
        //a b: a태그 하위의 모든 b
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.vgm_pop .popup_dim')
      } else {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.popup_dim')
      }
    },
    //VGM 컨테이너 불러오기
    async getVgm () {
      const props = this.parentInfo

      const formData = {
        blNo: props.blNo,
        methCd: props.methCd,
        type: props.type,
        uiFlag: props.uiFlag
      }

      await bookings.getVgm(formData).then((result) => {
        const res = result.data
        this.formData.blNo = res.blNo
        this.formData.bkgList = res.bkgList
        this.formData.tkCntrExistsYn = res.tkCntrExistsYn // Tank Container 존재 여부
        const vgmList = res.vgmList

        if (vgmList !== undefined) {
          vgmList.forEach(v => {
            v.orgActTareWt = v.actTareWt
            v.orgWt = v.wt
          })
        }

        this.formData.vgmList = res.vgmList

        this.formData.viewType = res.viewType
        this.formData.cntrList = res.cntrList
        this.formData.rawCntrWtList = res.rawCntrWtList
        this.popupParams = res.dgCertiList
        if (res.dgCertiList.length > 0) {
          this.popupParams[0] = { ...this.popupParams[0], ...{ originalName: res.dgCertiList[0].fileNm, size: res.dgCertiList[0].fileSz, fileId: 'http://common.ekmtc:9000/common/commons/file-download/commons?messageIdSeq=1&type=VGM&blNo=' + this.formData.blNo } }
        }

        let totalQty = 0
        let totalFini = 0
        let totalWt = 0

        this.formData.vgmList.forEach(i => {
          i.bkgNo = this.formData.bkgList[this.formData.bkgList.length - 1]

          //TOTAL 계산
          //211119 : 기존과 계산 상이하여 수정 및 코드 변경
          //vgm bkgNo가 없고 main bkgNo가 1개인 경우, 그 이외인 경우 bkgNo setting
          let tempBkgNo = ''
          if (!i.bkgNo && this.formData.bkgList.length === 1) {
            tempBkgNo = this.formData.bkgList[this.formData.bkgList.length - 1]
          } else {
            tempBkgNo = i.bkgNo
          }

          //vgm total setting
          if (tempBkgNo) {
            totalWt += Number(i.wt || 0)
            totalQty = this.formData.vgmList.length

            if (i.wt && i.wt !== 0) {
              totalFini += 1
            }
          }

          if (this.$ekmtcCommon.isEmpty(i.msrMethCd)) {
            i.msrMethCd = ''
          }
        })
        //
        //[Total-Cntr Qty] - asis 단순히 wt 태그 개수(frm.WT.length) 셋팅
        this.formData.vgmList.totalQty = totalQty
        //[Total-Vgm Finished]
        this.formData.vgmList.totalFini = totalFini
        //[Total-Weight]
        this.formData.vgmList.totalWt = Math.round(totalWt * 10000) / 10000

        return false
      })
      .catch(err => {
        console.log(err)
      })
    },
    async vgmUpdate () {
      let cntrArrCnt = 0
      //벨리데이션
      let vgmList = this.formData.vgmList

      if (vgmList.length === 0) {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.010'))
          return
      }

      for (let i = 0; i < vgmList.length; i++) {
        if (vgmList.length > 1) {
          if (!vgmList[i].wt && !vgmList[i].msrAdmrSgn && !vgmList[i].msrDtm && !vgmList[i].msrCrtfNo) {
            continue
          }
        }

        const vgm = document.querySelector('.vgm_area')

        //TARE WEIGHT값이 없는 경우
        const tareWtElem = vgm.querySelector('#tareWt_' + i)
        if (tareWtElem) { // Tare Weight 컴포넌트가 수정 가능 상태인 경우만 체크
          this.$ekmtcCommon.hideErrorTooltip(tareWtElem)
          if (vgmList[i].msrMethCd === 'SM2' && !this.chkWeight(vgmList[i].actTareWt, tareWtElem)) {
            return
          }
        }

        //VGM WEIGHT값이 없는 경우
        const elem = vgm.querySelector('#wt_' + i)
        this.$ekmtcCommon.hideErrorTooltip(elem)
        // chkWeight에서 체크 함으로 주석처리
        // if (!vgmList[i].wt) {
        //   this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.ONEX050G020.029')) //Please Input Weight.
        //   return
        // }

        //입력값 관련 벨리데이션
        if (!this.chkWeight(vgmList[i].wt, elem)) {
          return
        }

        //컨테이너별 오버웨이트 체크
        if (vgmList[i].wt && Number(vgmList[i].wt) > Number(vgmList[i].overWt)) {
          this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.ONEX050G020.030')) //컨테이너 입력 제한 총중량을 초과합니다.\nWeight 재확인 바랍니다.
          return
        }

        if (this.userCtrCd !== 'CN') {
          //[신규 체크 로직] - B/L중량(cntrWt)이 VGM보다 큰 경우
          //const rawCntrWt = this.formData.rawCntrWtList[i].wt
          let rawCntrWtListList = []
          rawCntrWtListList = this.formData.rawCntrWtList

          Array.from(rawCntrWtListList).forEach(el => {
            if (vgmList[i].cntrNo === el.cntrNo) {
                if (el.wt > vgmList[i].wt) {
                  this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.ONEX050G020.042'))
                  return
                }
                //[신규 체크 로직] - B/L중량(cntrWt)과 VGM이 10000이상 차이가 나는 경우(보류)
                if (vgmList[i].wt - el.wt >= 10000) {
                  this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.ONEX050G020.043'))
                }
            }
          })
        }

        //컨테이너 사이즈 20인 경우 2000미만, 40인 경우 4000미만으로 입력하도록 체크
        if (vgmList[i].cntrSzCd === '20') {
          if (Number(vgmList[i].wt) < 2000) {
            this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.ONEX050G020.031')) //Please check VGM.
            return
          }
        } else if (vgmList[i].cntrSzCd === '40') {
          if (Number(vgmList[i].wt) < 4000) {
            this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.ONEX050G020.031')) //Please check VGM.
            return
          }
        }

        //METHOD 체크1
        const methodEl = vgm.querySelector('#method_' + i)
        this.$ekmtcCommon.hideErrorTooltip(methodEl)
        //if (this.userCtrCd === 'KR') {
        if (!vgmList[i].msrMethCd) {
          if (['KR', 'IN'].indexOf(this.userCtrCd) >= 0) {
            this.$ekmtcCommon.showErrorTooltip(methodEl, this.$t('msg.ONEX050G020.032')) //Please Input Method.
            return
          } else if (this.userCtrCd === 'CN' && ['SHA', 'SZP', 'SHK', 'CAN', 'DLC'].indexOf(this.memberDetail.bkgPlcCd) >= 0) { // 상해(SHA), 심천(SZP, SHK), 광저우(CAN), 대련(DLC)
            this.$ekmtcCommon.showErrorTooltip(methodEl, this.$t('msg.ONEX050G020.032')) //Please Input Method.
            return
          }
        }

        //METHOD 체크2
        if (vgmList[i].msrMethCd !== 'SM1' && vgmList[i].msrMethCd !== 'SM2' && vgmList[i].msrMethCd !== '1' && vgmList[i].msrMethCd !== '2' && vgmList[i].msrMethCd) {
          this.$ekmtcCommon.showErrorTooltip(methodEl, this.$t('msg.ONEX050G020.033')) //Please Check Method.
          return
        }

        //Signature 체크
        const signEl = vgm.querySelector('#sign_' + i)
        this.$ekmtcCommon.hideErrorTooltip(signEl)
        if (!vgmList[i].msrAdmrSgn) {
          if (this.userCtrCd === 'KR') {
            this.$ekmtcCommon.showErrorTooltip(signEl, this.$t('msg.ONEX050G020.034')) //Please Input Signature.
            return
          } else if (this.userCtrCd === 'CN' && ['SZP', 'SHK'].indexOf(this.memberDetail.bkgPlcCd) >= 0) { // 심천(SZP, SHK)
            this.$ekmtcCommon.showErrorTooltip(signEl, this.$t('msg.ONEX050G020.034')) //Please Input Signature.
            return
          }
        }

        //DTM 체크
        const dtmEl = vgm.querySelector('#dtm_' + i)
        this.$ekmtcCommon.hideErrorTooltip(dtmEl)
        if (vgmList[i].msrDtm && !this.$ekmtcCommon.isValidDate(vgmList[i].msrDtm, 8)) {
          this.$ekmtcCommon.showErrorTooltip(dtmEl, this.$t('art.CMATK203') + this.$t('tem.CMAT002'))
          return
        }

        //BKG No. 체크1
        const bkgNoEl = vgm.querySelector('#bkgNo_' + i)
        this.$ekmtcCommon.hideErrorTooltip(bkgNoEl)
        if (this.userCtrCd === 'KR') {
          //let tempBkgNo = ''
          // if (!vgmList[i].bkgNo && this.formData.bkgList.length === 1) {
          //   tempBkgNo = this.formData.bkgList[this.formData.bkgList.length - 1]
          // } else {
          //   tempBkgNo = vgmList[i].bkgNo
          // }

          // if (!tempBkgNo) {
          //   this.$ekmtcCommon.showErrorTooltip(bkgNoEl, this.$t('msg.ONEX050G020.035')) //Please Input BKG No.
          //   return
          // }

          if (!vgmList[i].bkgNo) {
            this.$ekmtcCommon.showErrorTooltip(bkgNoEl, this.$t('msg.ONEX050G020.035')) //Please Input BKG No.
            return
          }
        }

        //컨테이너 체크
        const cntrEl = vgm.querySelector('#cntr_' + i)
        this.$ekmtcCommon.hideErrorTooltip(cntrEl)
        let cntrYn = ''

        if (this.formData.viewType !== 'SHAVGM') {
          this.formData.cntrList.map((cntrNo) => {
            if (cntrNo === vgmList[i].cntrNo) {
              cntrYn = 'Y'
            }
          })

          if (!cntrYn) {
            this.$ekmtcCommon.showErrorTooltip(cntrEl, this.$t('msg.ONEX050G020.036') + vgmList[i].cntrNo) //Please Check Container No.
            return
          }
        }

        //BKG No. 체크2
        let bkgYn = ''
        this.formData.bkgList.map((bkgNo) => {
          let tempBkgNo = ''
          if (!vgmList[i].bkgNo && this.formData.bkgList.length === 1) {
            tempBkgNo = this.formData.bkgList[this.formData.bkgList.length - 1]
          } else {
            tempBkgNo = vgmList[i].bkgNo
          }
          if (bkgNo === tempBkgNo) {
            bkgYn = 'Y'
          }
        })

        if (!bkgYn) {
          this.$ekmtcCommon.showErrorTooltip(bkgNoEl, this.$t('msg.ONEX050G020.037')) //Please Check Booking No.
          return
        }
        cntrArrCnt += 1
      }

      //컨테이너 중복 체크
      for (let i = 0; i < cntrArrCnt; i++) {
        let temp = this.formData.vgmList[i].cntrNo
        for (let j = 0; j < cntrArrCnt; j++) {
          if (i !== j) {
            if (temp === this.formData.vgmList[j].cntrNo) {
              this.$ekmtcCommon.alertDefault(this.$t('art.CMATK177')) //Container No가 중복됩니다.
              return
            }
          }
        }
      }

      const props = this.parentInfo

      const formData = {
        blNo: props.blNo,
        vgmDetailList: this.formData.vgmList,
        type: props.type
      }
      let successFlag = true

      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.290'), useConfirmBtn: true })) {
        bookings.vgmUpdate(formData).then((item) => {
          if (!item) {
            successFlag = false
          }
        }).catch((err) => {
          console.log(err)
          successFlag = false
        }).finally(() => {
          successFlag === true ? this.$ekmtcCommon.alertDefault(this.$t('art.CMATK383')) : this.$ekmtcCommon.alertDefault(this.$t('js.common.004'))
          this.$emit('close')
        })
      }
    },
    async vgmDelete (vgmSeq, cntrNo) {
      if (cntrNo) {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('tem.CMAT023'), useConfirmBtn: true })) {
          bookings.vgmDelete(vgmSeq).then((result) => {
            if (result.data === 'Y') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050G020.038')) //해당 컨테이너의 VGM이 정상적으로 삭제되었습니다.
              this.getVgm()
            }
          })
        }
      }
    },
    //업로드 팝업 열기
    uploadPopup (compNm) {
        console.log('compNm =' + compNm)
        this.customComponent = compNm

        if (compNm === 'vgm-upload-pop') {
          this.$ekmtcCommon.layerOpen('.vgm_pop .popup_dim')
        } else {
          this.popupParams = {}
        }
    },
    //중량 입력 벨리데이션
    chkWeight (value, elem) {
      let val = ''
      if (value) {
        val = String(value).replace(/,/g, '')
      }

      if (!val || val === '0') {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.ONEX050G020.029')) //Please Input Weight.
        return false
      }

      let array = []
      array = val.split('.')
      const vgm = document.querySelector('.vgm_area')
      //const elem = vgm.querySelector('#wt_' + index)
      this.$ekmtcCommon.hideErrorTooltip(elem)

      if ((array.length > 1 && array[1].length > 3) || array.length > 2) {
        elem.value = ''
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('js.utillmanager.013')) //소수점이하 3자리까지만 가능합니다.
        return false
      }

      let vNormal = /[^0-9.]/g
      if (vNormal.test(val) || isNaN(parseInt(val))) {
        elem.value = ''
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK036')) //숫자만 입력하세요.
        return false
      }

      let length = array[0].length
      if (length > 10) {
        elem.value = ''
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.CSBK100.318')) //정수는 10자리까지 입력가능합니다.
        return false
      }
      elem.value = val

      return true
    },
    //엑셀 템플릿 다운로드
    async excelDownload (fileName) {
      const result = await bookings.excelDownload(fileName)
      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = fileName
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    },
    //파일추가시 가공
    arrangeUploadParam () {
      this.firstUploadFlag = true
      this.formData.uploadFileInfos = []
      this.popupParams.map((item) => (item.newFlag = item.new))
      this.formData.uploadFileInfos = this.popupParams
    },
    //엑셀 업로드
    async excelUpload () {
      let fileName = document.getElementById('inpFile').value

      //기본 벨리데이션
      if (!fileName) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK169')) //등록할 파일을 선택하세요.
        return
      }
      if (!new RegExp('xls$', 'i').test(fileName)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050G020.039')) //Please choose one of the excel files. [.xls]
      } else {
        //엑셀 데이터 읽은 후 vgm 셋팅
        let params = {
                      fileId: this.tempFile.id,
                      methCd: this.parentInfo.methCd,
                      tkCntrExistsYn: this.formData.tkCntrExistsYn
                     }
        const result = await bookings.excelUpload(params)
        if (result.data.xlsErrorYn === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050G020.044')) // 잘못된 양식입니다.
          this.getVgm() // 초기값 다시 불러오게 처리
        } else {
          this.formData.vgmList = result.data.vgms.map((item) => {
            if (!item.bkgNo) {
              if (this.formData.bkgList.length !== 0) {
                item.bkgNo = this.formData.bkgList[this.formData.bkgList.length - 1]
                item.cntrNo = item.cntrNo.toUpperCase()
              }
            }

            return item
          })
        }

        let totalQty = 0
        let totalFini = 0
        let totalWt = 0

        this.formData.vgmList.forEach(i => {
          //TOTAL 계산
          //211119 : 기존과 계산 상이하여 수정 및 코드 변경
          //vgm bkgNo가 없고 main bkgNo가 1개인 경우, 그 이외인 경우 bkgNo setting
          let tempBkgNo = ''
          if (!i.bkgNo && this.formData.bkgList.length === 1) {
            tempBkgNo = this.formData.bkgList[this.formData.bkgList.length - 1]
          } else {
            tempBkgNo = i.bkgNo
          }

          //vgm total setting
          if (tempBkgNo) {
            totalWt += Number(i.wt || 0)
            totalQty = this.formData.vgmList.length

            if (i.wt && i.wt !== 0) {
              totalFini += 1
            }
          }
        })
        //
        //[Total-Cntr Qty] - asis 단순히 wt 태그 개수(frm.WT.length) 셋팅
        this.formData.vgmList.totalQty = totalQty
        //[Total-Vgm Finished]
        this.formData.vgmList.totalFini = totalFini
        //[Total-Weight]
        this.formData.vgmList.totalWt = Math.round(totalWt * 10000) / 10000
      }
    },
    //Certi 업로드
    async certiUpload () {
      let fileName = document.getElementById('certiFile').value

      if (!fileName) {
        if (!this.firstUploadFlag) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050G020.040')) //파일을 등록해주세요.
          return
        } else {
          // 삭제 로직
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('tem.CMAT023'), useConfirmBtn: true })) {
            const result = await bookings.vgmCertiDelete(this.formData.blNo)
            this.$ekmtcCommon.alertDefault(this.$t('art.CMATK384')) //삭제하였습니다
            return
          } else {
            return
          }
        }
      } else {
        if (!this.popupParams[0].newFlag) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050G020.041')) //새로운 파일을 등록해주세요.
          return
        }
      }

      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('tem.CMAT022'), useConfirmBtn: true })) {
        const result = await bookings.vgmCertiUpload(this.formData)
        if (result.headers.respcode === 'C0000') {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK383')) //VGM Certi 등록.
        }
      }
    },
    //Signature 입력시 한글입력 관련 벨리데이션 체크
    valEngCheck (e) {
      //let vNormal = /[^a-zA-Z.~`!@#$%^&*()_+=|?\\'\\"?/><,-\\[\]{};:^\s]/g
      let vNormal = /[^a-zA-Z^\s]/g
      if (vNormal.test(e.target.value) && e.target.value) {
        this.$ekmtcCommon.alertDefault('Input should be in English')
        //this.$ekmtcCommon.onlyEngValue(e)
        e.target.value = e.target.value.replace(vNormal, '')
      }
    },
    //method SM2인 경우 자동계산
    autoCalculate (index) {
      let tempVo = this.formData.vgmList[index]
      let method = tempVo.msrMethCd

      //method SM2인경우 AND 화주소유컨테이너 또는 SOC가 아닌 경우
      // if (method === 'SM2' && tempVo.shprVanYn !== 'Y' && tempVo.cgoModeCd !== '04') {
      if (this.formData.tkCntrExistsYn !== 'Y') { // 탱크컨테이너가 존재하지 않는 경우
        if (method === 'SM2') {
          if (this.userCtrCd !== 'CN') {
            const rawCntr = this.formData.rawCntrWtList.filter((item) => {
              if (item.cntrNo === tempVo.cntrNo) {
                return item
              }
            })

            let rawCntrWt = 0
            if (rawCntr.length > 0) {
              rawCntrWt = rawCntr[0].wt
            }

            let tareWt = Number(tempVo.actTareWt) || 0
            let wt = Number(rawCntrWt) || 0
            tempVo.wt = 0
            tempVo.wt = (tareWt + wt).toFixed(3)
          }
        } else {
          tempVo.wt = tempVo.orgWt
          //tempVo.actTareWt = tempVo.orgActTareWt
          tempVo.actTareWt = tempVo.basTareWt
        }

        this.$set(this.formData.vgmList, index, tempVo)
      }

      // hide Error Tooltip
      const elem = document.querySelector('.vgm_area').querySelector('#tareWt_' + index)
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    //컨테이너 Number 벨리데이션
    async searchCntrNoChk (cntrNo, index) {
      if (this.formData.viewType === 'SHAVGM') {
        let tempVgmList = this.formData.vgmList[index]
        const result = await bookings.cntrNocheck(cntrNo)
        const data = result.data

        tempVgmList.actTareWt = data.tareWt
        tempVgmList.overWt = data.overWt
        tempVgmList.cntrSzCd = data.cntrSzCd

        if (data.cntrYn === 'N') {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK363')) //컨테이너 Number를 확인하세요
          tempVgmList.cntrNo = ''
          tempVgmList.cntrSzCd = ''
          document.getElementById('cntr_' + index).focus()
        }
        this.$set(this.formData.vgmList, index, tempVgmList)
      }
    },
    //파일첨부 클릭
    fnFileUpload () {
      //file 타입 태그 클릭 이벤트 -> change이벤트 호출 -> 임시저장 -> excel upload 클릭시 file아이디 보내서 받은 것을 바이트스트림으로 변환후 받기
      const formData = new FormData()
      const inpFile = document.querySelector('#inpFile')

      formData.append('files', inpFile.files[0])
      this.filename = inpFile.files[0].name

      commons.tempFileUpload(formData).then((result) => {
          const fileId = result.data.fileId
          this.tempFile.id = fileId
          this.tempFile.msg = 'SELECT * FROM CM530I WHERE FILE_ID = ' + fileId + ';'
      })
      .catch(err => {
        console.log(err)
      })
    },
    handleInputDivClick () {
      this.$refs.uploadFile.click()
    },
    async checkVgmDelYn () {
      const blNo = this.parentInfo.blNo

      await trans.findVgmDocDtm(blNo).then((result) => {
        const vgmDocYn = result.data

        this.vgmDelYn = vgmDocYn
      })
    }
  }
})
</script>
<style scoped>
.sp {width: 3px;display: inline-block;}
#vocForm .grid.service_rdo li{margin-left: 13px;margin-right: 0;}
#vocForm .grid.service_rdo li:first-child{margin-left: 0;}
.input_calendar.mid, .input_calendar.mid input{width: 100px}
.div-file {
  display: inline-block;
}
.div-file > button {
  display: inline-block;
}
</style>
